import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		routerList: []
	},
	getters: {
		routerList(state) {
			return state.routerList
		}
	},
	mutations: {
		addRouterList(state, data) {
			state.routerList.push(data)
		},
		removeRouterList(state, data) {
			state.routerList.splice(data, 1)
		}
	},
	actions: {
		setRouterList(context, data) {
			context.commit('inputCount', data)
		}
	},
	modules: {}
})